<template>
  <div>
    <h2 class="p-b-10">拜访转化率分布</h2>
    <div
      id="bottom_chart"
      style="width:100%;height:280px;"
      class="m-b-10"
    ></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { listMicroIndexGbMonth } from '@/api/dw/micro'
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins],
  data () {
    return {
      showChart: false,
      chartDate: [],
      industryId: null,
      userId: null
    }
  },
  computed: {
    publisherId () {
      return this.$store.getters.token.userInfo.publisherId
    },
    dateRange () {
      return this.$store.state.situationWeb.dateRange
    },
    selectedIndustryId () {
      return this.$store.state.situationWeb.selectedIndustryId
    },
    selectedUserId () {
      return this.$store.state.situationWeb.selectedUserId
    }
  },
  methods: {
    getChartData () {
      const query = {
        publisherId: this.publisherId,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        industryId: this.industryId,
        userId: this.userId
      }
      listMicroIndexGbMonth(query).then(res => {
        if (res && !res.errcode) {
          this.showChart = res.length
          this.chartDate = res
        } else {
          this.chartDate = []
          this.showChart = false
        }
        this.initChart(this.chartDate)
      })
    },
    initChart (data = []) {
      this.$nextTick(() => {
        let chart = echarts.getInstanceByDom(document.getElementById('bottom_chart'))
        if (chart == null) { // 如果不存在，就进行初始化
          chart = echarts.init(document.getElementById('bottom_chart'))
        }
        if (this.showChart) {
          const option = {
            // color: ['#3A4DE9', '#91CC75'],
            grid: {
              left: '6%',
              right: '6%'
            },
            textStyle: {
              color: this.reportFontColor
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                crossStyle: {
                  color: '#999'
                }
              }
            },
            legend: {
              data: ['拜访数', '签约数', '转化率'],
              textStyle: {
                color: this.reportFontColor
              }
            },
            xAxis: [
              {
                type: 'category',
                data: data.map(x => x.name),
                axisPointer: {
                  type: 'shadow'
                },
                axisLabel: {
                  interval: 0,
                  rotate: 40,
                  color: '#c5c8ce'
                }
              }
            ],
            yAxis: [
              {
                type: 'value',
                name: '数量',
                position: 'left',
                min: 0,
                axisLine: {
                  show: true
                }
              },
              {
                type: 'value',
                name: '数量',
                position: 'left',
                min: 0,
                axisLine: {
                  show: true
                }
              },
              {
                type: 'value',
                name: '转化率',
                min: 0,
                position: 'right',
                axisLine: {
                  show: true
                },
                axisLabel: {
                  formatter: '{value} %'
                }
              }
            ],
            series: [
              {
                name: '拜访数',
                type: 'bar',
                data: data.map(x => x.totalNumber)
              },
              {
                name: '签约数',
                type: 'bar',
                yAxisIndex: 1,
                data: data.map(x => x.actualNumber)
              },
              {
                name: '转化率',
                type: 'line',
                yAxisIndex: 2,
                data: data.map(x => x.rate)
              }
            ]
          }

          chart.setOption(option, true)
          window.addEventListener('resize', function () {
            chart.resize()
          })
        } else {
          chart.dispose()
        }
      })
    }
  },
  watch: {
    dateRange: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.startDate && val.endDate) {
          this.getChartData()
        }
      }
    },
    selectedIndustryId (val) {
      this.userId = null
      this.industryId = val
      this.getChartData()
    },
    selectedUserId (val) {
      this.userId = val
      this.industryId = null
      this.getChartData()
    }
  }
}
</script>
